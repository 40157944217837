import { Button, Form, Input, message, Modal, Spin } from "cldesign"
import { useRef, useState } from "react"
import { api_changePwd } from "../../../api/user"
import ModalBaseLogic from "../../../components/modalBaseLogic"
import { ValidateStatus } from 'antd/lib/form/FormItem'
/**弹窗-修改密码 */
const ModalChangePwd = (props: UIComponent.ModalBaseProps) => {
    const _this = ModalChangePwdLogic(props)
    return (
        <Modal title="修改密码"
            visible={_this.modalShow}
            onCancel={_this.handleCancel}
            onOk={_this.handleConfirm}
            destroyOnClose
            footer={[
                <Button key="cancel" onClick={_this.handleCancel} loading={_this.working}>取消</Button>,
                <Button key="confirm" type="primary" onClick={_this.handleConfirm} loading={_this.working}>确定</Button>
            ]}>
            <Spin spinning={_this.working}>
                <Form form={_this.form} labelCol={{ span: 4 }}>
                    <Form.Item name="newPassword" label="新密码" rules={[{ required: true, message: '请输入新密码' }]}>
                        <Input.Password onChange={e => _this.formdata.current = {
                            ..._this.formdata.current,
                            newPassword: e.target.value.trim()
                        }} maxLength={50} />
                    </Form.Item>
                    <Form.Item name="confirmPassword" label="确认密码" validateStatus={_this.formValid.confirmPwd.status} help={_this.formValid.confirmPwd.msg} rules={[{ required: true }]}>
                        <Input.Password onChange={e => {
                            _this.formdata.current = {
                                ..._this.formdata.current,
                                confirmPassword: e.target.value.trim()
                            }
                            _this.handleFormValueChange()
                        }} maxLength={50} />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    )
}

export default ModalChangePwd

const ModalChangePwdLogic = (props: UIComponent.ModalBaseProps) => {
    const [form] = Form.useForm()
    const formdata = useRef({
        newPassword: '',
        confirmPassword: ''
    })
    const modalBase = ModalBaseLogic(props, {
        onShow: () => {
            formdata.current = {
                newPassword: '',
                confirmPassword: ''
            }
            form.setFieldsValue({
                ...formdata.current
            })
        }
    })
    const [formValid, set_formValid] = useState({
        confirmPwd: {
            status: 'success' as ValidateStatus,
            msg: ''
        }
    })
    const handleConfirm = async () => {
        try {
            await form.validateFields()
            if (!handleFormValueChange()) {
                return
            }
            modalBase.setWorking(true)
            const result = await api_changePwd({
                ...formdata.current
            })
            modalBase.setWorking(false)
            if (result.success) {
                message.success('操作成功')
                modalBase.handleCancel()
            } else {
                message.error(`操作失败,反馈:${result.msg}`)
            }
        } catch {

        }
    }
    const handleFormValueChange = () => {
        if (!formdata.current.confirmPassword) {
            set_formValid({
                confirmPwd: {
                    status: 'error',
                    msg: '请再输入一次新密码'
                }
            })
            return false
        } else if (formdata.current.newPassword !== formdata.current.confirmPassword) {
            set_formValid({
                confirmPwd: {
                    status: 'error',
                    msg: '新密码与确认密码不一致'
                }
            })
            return false
        } else {
            set_formValid({
                confirmPwd: {
                    status: 'success',
                    msg: ''
                }
            })
            return true
        }
    }
    return {
        ...modalBase,
        form,
        formdata,
        formValid, set_formValid,
        handleConfirm,
        handleFormValueChange
    }
}