import { createSlice, PayloadAction } from '@reduxjs/toolkit'
//声明state类型
export declare type UserState = {
    name: string
    code: string
    id: number
    roles: Models.Role[]
    email: string
    menus: Models.Menu[]
    [key: string]: any
}
//初始化state值
const initialState: UserState = {
    name: '',
    code: '',
    id: 0,
    roles: [],
    email: '',
    menus: []
}
//初始化slice
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        //声明用于修改state的函数
        setUserInfo: (state, action: PayloadAction<UserState>) => {
            for (let k of Object.keys(action.payload)) {
                state[k] = action.payload[k]
            }
        }
    }
})
//导出reducer中的函数
export const { setUserInfo } = userSlice.actions

export default userSlice;

