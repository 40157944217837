import axios from "axios"

/**登录 */
export const api_doLogin = async (email: string, password: string) => {
    let result: Models.JsonResult<string> = {
        success: false
    }
    const postdata = new FormData()
    postdata.append('email', email)
    postdata.append('password', password)
    postdata.append('expireType', "24")
    try {
        const res = await axios({
            url: 'login/',
            headers: { 'Content-Type': 'multipart/form-data' },
            data: postdata,
            method: 'post'
        })
        result = res.data
    } catch (error: any) {
        console.warn(error)
        result.msg = error.message
    }
    return result
}
/**获取用户信息 */
export const api_getUserInfo = async () => {
    let result: Models.JsonResult<Models.User> = {
        success: false
    }
    try {
        const res = await axios({
            url: 'user/get-user-info'
        })
        result = res.data
    } catch (error: any) {
        console.warn(error)
        result.msg = error.message
    }
    return result
}
/**获取用户列表
 * @param isInner 账号类型，1=内部账号，0=外部账号，-1=查询全部
 */
export const api_getUserList = async (name = '', pageIndex = 1, pageSize = 5, isInner = -1) => {
    let result: Models.JsonResult<Models.PagedResult<Models.User>> = {
        success: false
    }
    try {
        const res = await axios({
            url: 'user/get-list',
            params: { name, pageIndex, pageSize, isInner }
        })
        result = res.data
    } catch (error: any) {
        console.warn(error)
        result.msg = error.message
    }
    return result
}
declare type AddUserPostData = {
    account: string
    email: string
    name: string
    id?: number
    isInner?: number
    state: number
}
/**新增用户 */
export const api_addUser = async (data: AddUserPostData) => {
    let result: Models.JsonResult<any> = {
        success: false
    }
    try {
        const res = await axios({
            url: `user/create`,
            data,
            method: 'post'
        })
        result = res.data
    } catch (error: any) {
        console.warn(error)
        result.msg = error.message
    }
    return result
}
/**修改用户信息 */
export const api_updateUser = async (data: AddUserPostData) => {
    let result: Models.JsonResult<any> = {
        success: false
    }
    try {
        const res = await axios({
            url: `user/update`,
            data,
            method: 'post'
        })
        result = res.data
    } catch (error: any) {
        console.warn(error)
        result.msg = error.message
    }
    return result
}
/**删除用户 */
export const api_removeUser = async (id: number) => {
    let result: Models.JsonResult<any> = {
        success: false
    }
    try {
        const res = await axios({
            url: `user/delete`,
            params: { id },
            method: 'delete'
        })
        result = res.data
    } catch (error: any) {
        console.warn(error)
        result.msg = error.message
    }
    return result
}
/**重置用户密码 */
export const api_resetUserPwd = async (id: number) => {
    let result: Models.JsonResult<any> = {
        success: false
    }
    try {
        const res = await axios({
            url: 'user/reset-password',
            params: { id }
        })
        result = res.data
    } catch (error: any) {
        console.warn(error)
        result.msg = error.message
    }
    return result
}
declare type ChangePwdPostData = {
    confirmPassword: string,
    newPassword: string
}
export const api_changePwd = async (data: ChangePwdPostData) => {
    let result: Models.JsonResult<any> = {
        success: false
    }
    try {
        const res = await axios({
            url: `user/change-password`,
            data,
            method: 'post'
        })
        result = res.data
    } catch (error: any) {
        console.warn(error)
        result.msg = error.message
    }
    return result
}