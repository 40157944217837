import { useEffect, useState } from "react"
/**公共弹窗的基础逻辑 */
const ModalBaseLogic = (props: UIComponent.ModalBaseProps, options?: {
    onShow?: () => void
}) => {
    const [modalShow, setModalShow] = useState(false)
    const [working, setWorking] = useState(false)
    /**关闭弹窗
     * @param beforeClose 关闭前处理，如果返回true，则阻止关闭
     */
    const handleCancel = (e?: React.MouseEvent<HTMLElement, MouseEvent>, onBeforeClose?: () => boolean) => {
        if (working) {
            return
        }
        if (onBeforeClose) {
            if (onBeforeClose()) {
                return
            }
        }
        setModalShow(false)
        if (props.onClose) {
            props.onClose()
        }
    }
    useEffect(() => {
        setModalShow(props.visible)
        if (props.visible && options?.onShow) {
            options.onShow()
        }
    }, [props.visible])
    return {
        modalShow, setModalShow,
        working, setWorking,
        handleCancel
    }
}

export default ModalBaseLogic