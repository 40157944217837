export const getQueryString = (name: string) => {
    const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i")
    const r = window.location.search.substr(1).match(reg)
    if (r) {
        return decodeURIComponent(r[2])
    }
    else {
        return ""
    }
}
export const Blob_2_Base64 = (data: Blob) => {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(data)
        reader.onload = () => resolve(reader.result as string)
        reader.onerror = error => reject(error)
    })
}