import axios from "axios"
/**获取序列号 */
export const api_getSerialNo = async () => {
    let result: Models.JsonResult<string> = {
        success: false
    }
    try {
        const res = await axios({
            url: 'base/get-serial-no'
        })
        result = res.data
    } catch (error: any) {
        console.warn(error.message)
        result.msg = error.message
    }
    return result
}
/**读取ansible hosts文件 */
export const getAnsibleHosts = async () => {
    let result: Models.JsonResult<string> = {
        success: false
    }
    try {
        const res = await axios({
            url: 'base/get-ansible-hosts'
        })
        result = res.data
    } catch (error: any) {
        console.warn(error.message)
        result.msg = error.message
    }
    return result
}
/**转换图片路径 */
export const convertImagePath = (path: string) => {
    if (!path) {
        return ''
    }
    return `/api/base/image?path=${path}`
}
/**转换静态文件路径 */
export const convertStaticFilePath = (path: string) => {
    if (!path) {
        return ''
    }
    return `/api/base/download-static-file?path=${path}`
}
/**检查绑定 */
export const api_checkBind = async () => {
    let result: Models.JsonResult<boolean> = {
        success: false
    }
    try {
        const res = await axios({
            url: 'login/check-bind'
        })
        result = res.data
    } catch (error: any) {
        console.warn(error.message)
        result.msg = error.message
    }
    return result
}
/**获取附件内容 */
export const api_getAttachFileContent = async (attachmentId: number) => {
    try {
        const res = await axios({
            url: 'prepublish/download-attachment',
            params: { attachmentId }
        })
        return res.data as string
    } catch (error: any) {
        console.warn(error.message)
        return ''
    }
}
/**取得平台架构 */
export async function api_getArch() {
    let result: Models.JsonResult<string> = {
        data: 'x86_64'
    }
    try {
        const res = await axios({
            url: 'base/get-arch'
        })
        result = res.data
    } catch (error: any) {
        console.warn(error.message)
        result.msg = error.message
    }
    return result
}