import 'antd/dist/antd.min.css'
import 'antd/dist/antd.variable.less'
import zhCN from 'antd/lib/locale/zh_CN'
import { ConfigProvider } from 'antd'
import axios from 'axios'
import 'cldesign/es/style/index.less'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import RouterConfig from './routerconfig'
import './@utils/dialogdrag'
import { Provider } from 'react-redux'
import store from './store'
import { message } from 'cldesign'



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <Provider store={store}>
    <ConfigProvider locale={zhCN}>
      <RouterConfig />
    </ConfigProvider>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

axios.defaults.baseURL = `${window.StaticConfig.ApiPrefix||''}api/`

const token = localStorage.getItem('token')
if (token) {
  axios.defaults.headers.common['Authorization'] = token
}

// 添加响应拦截器
axios.interceptors.response.use(response => {
  // 对响应数据做点什么
  return response
}, async (error) => {
  //登录信息过期/无效
  if (error.response?.status === 401) {
    //TODO:await auth.check();
    window.location.href = '#/login'
  } else {
    message.error(<>
      WEBAPI出错
      <br />
      URL:{error.request.responseURL}
      <br />
      消息:{error.message}
    </>)
  }
  // 对响应错误做点什么
  return Promise.reject(error)
})
