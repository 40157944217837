import axios from "axios"
import { Button, Form, Input, message, Spin } from "cldesign"
import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { api_doLogin } from "../../api/user"
import { useAppDispatch, useAppSelector } from "../../store"
import { setLayoutState } from "../../store/layoutSlice"
import sty from './login.module.scss'
import { api_checkBind, api_getSerialNo } from "../../api/base"
import QRCode from 'easyqrcodejs'
import { getQueryString } from "../../@utils/url"

/**页面-登录 */
const PageLogin = () => {
    const l = PageLoginLogic()
    return (
        <div className={sty.root}>
            <Spin spinning={l.working}>
                {
                    !l.isBind ? (
                        <div className={`${sty.qrcode} ${sty.box}`}>
                            <label className={sty.tips}>扫码申请设备认证</label>
                            <br />
                            <br />
                            <div className={sty.qrcodebox} ref={l.setQrcodedom}></div>
                            <br />
                            认证完成,点击
                            <Button type="primary" onClick={() => l.setIsBind(true)}>自助登录</Button>
                        </div>
                    ) : (
                        <>
                            <Form form={l.form} layout='vertical' className={sty.box}>
                                <Form.Item name="account" label="邮箱" rules={[{ required: true, message: '请输入邮箱' }]}>
                                    <Input maxLength={50} onChange={e => l.handleEmailChange(e.target.value.trim())} addonAfter="@clinbrain.com" />
                                </Form.Item>

                                <Form.Item label="动态密码/固定密码" required tooltip="首次登录的用户通过修改密码确定固定密码">
                                    <Input.Group compact>
                                        <Form.Item
                                            noStyle
                                            name="pwd"
                                            rules={[{ required: true, message: '请输入密码' }]}
                                        >
                                            <Input.Password maxLength={150}
                                                onChange={e => l.formdata.current = {
                                                    ...l.formdata.current,
                                                    pwd: e.target.value.trim()
                                                }}
                                                onPressEnter={l.handleSubmit}
                                                style={{ width: 'calc(100% - 64px)' }}
                                            />
                                        </Form.Item>
                                        <Button type="primary" onClick={l.handleShowQR}>获取</Button>
                                    </Input.Group>
                                </Form.Item>
                            </Form>
                            {
                                l.showQRCode && (
                                    <div className={sty.qrcode}>
                                        <label className={sty.tips}>扫码获取动态密码</label>
                                        <div className={sty.qrcodebox} ref={l.setQrcodedom}></div>
                                    </div>
                                )
                            }
                            <Button type="primary" onClick={l.handleSubmit} className={sty.btnlogin}>登录</Button>
                        </>
                    )
                }

            </Spin>
        </div>
    )
}
export default PageLogin

export const devHosts = ['192.168.0.220', 'appstore.clinbrain.com', 'localhost']

const PageLoginLogic = () => {
    const [form] = Form.useForm()
    const formdata = useRef({
        account: '',
        pwd: ''
    })
    const [working, set_working] = useState(false)
    const [qrcodedata, setQrcodedata] = useState('')
    const [qrcodedom, setQrcodedom] = useState<HTMLDivElement | null>()
    const qrcode = useRef<QRCode>()
    const navigate = useNavigate()
    const layoutState = useAppSelector(state => state.layout)
    const [showQRCode, setShowQRCode] = useState(false)
    //客户是否已绑定
    const [isBind, setIsBind] = useState(false)
    const timerRef = useRef({
        timer: null as NodeJS.Timeout | null,
        serialno: ''
    })

    const dispatch = useAppDispatch()
    const handleSubmit = async () => {
        try {
            await form.validateFields()
            set_working(true)
            const result = await api_doLogin(`${formdata.current.account}@clinbrain.com`, formdata.current.pwd)
            set_working(false)
            if (result.success) {
                localStorage.setItem('token', result.data as string)
                axios.defaults.headers.common['Authorization'] = result.data as string
                navigate('/')
                dispatch(setLayoutState({
                    ...layoutState,
                    fromlogin: true
                }))
            } else {
                message.error(`登录失败,反馈:${result.msg}`)
            }
        } catch {

        }
    }
    //显示"动态密码"的二维码
    const handleShowQR = async () => {
        if (!formdata.current.account) {
            message.warning('请输入邮箱')
            return
        }
        set_working(true)
        const result_serialno = await api_getSerialNo()
        set_working(false)
        if (result_serialno.success && result_serialno.data) {
            const serialno = result_serialno.data
            const url = `${window.StaticConfig.ClinbrainHost}/api/login/send-dynamic-pwd?serialNo=${serialno}&email=${formdata.current.account}@clinbrain.com&expireType=24`
            const host = window.location.hostname
            //公司环境不需要扫码
            if (devHosts.includes(host)) {
                window.open(url)
            } else {
                setQrcodedata(url)
                setShowQRCode(true)
            }
        } else {
            message.error(`获取序列号失败,反馈:${result_serialno.msg}`)
        }
    }
    //生成"设备绑定"的二维码
    const generateQRCode_bindreq = async () => {
        if (!timerRef.current.serialno) {
            set_working(true)
            const result_serialno = await api_getSerialNo()
            set_working(false)
            if (result_serialno.success && result_serialno.data) {
                timerRef.current.serialno = result_serialno.data
            } else {
                message.error(`获取序列号失败,反馈:${result_serialno.msg}`)
                return
            }
        }
        const url = `${window.StaticConfig.ClinbrainHost}/?serialno=${timerRef.current.serialno}&time=${new Date().getTime()}#/bindreq`
        setQrcodedata(url)
    }
    const startFlashQRCode = () => {
        stopFlashQRCode()
        timerRef.current.timer = setInterval(generateQRCode_bindreq, window.StaticConfig.BindReq.QRRefreshInterval)
    }
    const stopFlashQRCode = () => {
        if (timerRef.current.timer) {
            clearInterval(timerRef.current.timer)
        }
    }

    const handleEmailChange = (value: string) => {
        const cidx = value.indexOf('@')
        if (cidx !== -1) {
            value = value.substring(0, cidx)
        }
        formdata.current = {
            ...formdata.current,
            account: value
        }
        form.setFieldsValue({
            ...formdata.current
        })
    }
    //检查设备绑定
    const checkBind = async () => {
        set_working(true)
        const result = await api_checkBind()
        set_working(false)
        if (result.success) {
            setIsBind(result.data || false)
            if (!result.data) {
                generateQRCode_bindreq()
            }
        } else {
            message.error(`检查绑定出错,反馈:${result.msg}`)
        }
    }
    useEffect(() => {
        document.title = '登录 - ClinStore'
        const _isbind = getQueryString('isbind')
        if (!_isbind) {
            checkBind()
        } else {
            setIsBind(_isbind === '1')
            if (_isbind === '0') {
                generateQRCode_bindreq()
            }
        }
        return () => {
            stopFlashQRCode()
        }
    }, [])
    useEffect(() => {
        if (isBind) {
            form.setFieldsValue({
                ...formdata.current
            })
            stopFlashQRCode()
        } else {
            startFlashQRCode()
        }
        return () => {
            stopFlashQRCode()
        }
    }, [isBind])
    useEffect(() => {
        if (!qrcodedom) {
            return
        }
        qrcode.current?.clear()
        qrcode.current = new QRCode(qrcodedom, {
            text: qrcodedata,
            width: 256,
            height: 256,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
            logo: '/img/header-logo.png'
        })
    }, [qrcodedom, qrcodedata])

    return {
        form,
        formdata,
        working, set_working,
        qrcodedata, setQrcodedata,
        qrcodedom, setQrcodedom,
        showQRCode, setShowQRCode,
        isBind, setIsBind,
        handleSubmit,
        handleEmailChange,
        handleShowQR
    }
}