import axios from "axios"
import { BackTop, Button, Dropdown, Layout, Menu, message, Skeleton, Tag } from "cldesign"
import { memo, Suspense, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import { Outlet } from "react-router-dom"
import { api_getUserInfo } from "../api/user"
import SVGICON_User from "../assets/svgicons/svg-user"
import { menuitems, MenuItemType, RouteItem } from "../routerconfig"
import { useAppDispatch, useAppSelector } from "../store"
import { handleRouteChange, setLayoutState, setPlatformArch } from "../store/layoutSlice"
import { setUserInfo } from "../store/userSlice"
import sty from './layout.module.scss'
import moment from "moment"
import ModalChangePwd from "./usermanage/coms/modalChangePwd"
import { ItemType } from "antdv5/es/menu/hooks/useItems"
import { api_getArch } from "../api/base"
import { devHosts } from './usermanage/login'
/**布局-前台 */
const MainLayout = memo(() => {
    const l = MainLayoutLogic()
    return (
        <>
            <Layout className={sty.root}>
                <Layout.Header className={sty['main-header']}>
                    <div className={sty.content}>
                        <div className={sty.left}>
                            <div className={sty.logobox}>
                                <img className={sty.logo} src="/img/faviconx160.png" />
                                ClinStore
                            </div>
                            <Menu items={l.allowMenus}
                                selectedKeys={[l.selectedItem?.menuitem.key || '']}
                                onClick={l.handleMenuItemClick}
                                mode="horizontal"
                                className={sty.navmenu}
                            />
                        </div>
                        <div className={sty.right}>
                            <div className={sty.customheader}>{l.layoutState.header}</div>
                            {
                                (l.selectedItem?.menuitem.key === 'installplat') && (
                                    <Tag color="red" className={sty.arch}>{l.platform === 'amd64' ? <>&nbsp;&nbsp;X86&nbsp;&nbsp;</> : 'ARM64'}</Tag>
                                )
                            }

                            <Dropdown overlay={<Menu items={l.profileMenus} />} className={sty.right}>
                                <label title={l.userInfo.name} className={sty.userinfo}><SVGICON_User />&nbsp;{l.userInfo.name}</label>
                            </Dropdown>
                        </div>
                    </div>
                </Layout.Header>
                <Layout.Content className={sty['main-body']} onScroll={e => l.handleContentScroll(e.nativeEvent)}>
                    <Suspense fallback={<Skeleton />}>
                        <Outlet />
                    </Suspense>
                    <div className={sty.footer}>
                        <img className={sty.logo} alt="logo" src="/img/footer-logo.png" />
                        <br />
                        <label className={sty.copyright}>Copyright @ 2019-{moment().year()} ClinBrain. All Rights Reserved</label>
                        {
                            window.StaticConfig.RefLinks?.length > 0 && (
                                <div className={sty.links}>
                                    {
                                        window.StaticConfig.RefLinks.map(d => (
                                            <a href={d.href} key={d.label} target="_blank" rel="nofollow noopener noreferrer">{d.label}</a>
                                        ))
                                    }
                                </div>
                            )
                        }
                    </div>
                    <BackTop target={() => l.backTopTarget} visibilityHeight={100} />
                </Layout.Content>
            </Layout>
            <ModalChangePwd visible={l.modalChangePwd.show} onClose={() => {
                l.setModalChangePwd({ show: false })
            }} />
        </>
    )
})

export default MainLayout

const map_archs: { [key: string]: string } = {
    'aarch64': 'arm64',
    'x86_64': 'amd64'
}

const MainLayoutLogic = () => {
    //当前选中的菜单项
    const [selectedItem, setSelectedItem] = useState<RouteItem | null>()
    const navigate = useNavigate()
    const location = useLocation()
    //能看到的菜单列表
    const [allowMenus, setAllowMenus] = useState<MenuItemType[]>([])
    const [filterMenus, setFilterMenus] = useState<RouteItem[]>([])
    //当前用户信息
    const userInfo = useAppSelector(state => state.user)
    const dispatch = useAppDispatch()
    const layoutState = useAppSelector(state => state.layout)
    const [container, setContainer] = useState<HTMLElement>()
    const backTopTarget = useMemo(() => {
        if (container) {
            return container
        }
        return window
    }, [container])
    const checkUserLockState = useRef({
        timer: undefined as NodeJS.Timer | undefined
    })
    const [modalChangePwd, setModalChangePwd] = useState({
        show: false
    })
    const [platform, setPlatform] = useState('amd64')

    //加载用户信息
    const loadUserInfo = async (refreshonly?: boolean) => {
        const token = localStorage.getItem('token')
        if (!token) {
            handleLogout()
        } else {
            axios.defaults.headers.common['Authorization'] = token
            const result = await api_getUserInfo()
            if (result.success && result.data) {
                if (result.data.state === 0) {
                    message.warn('您的账号已停用')
                    handleLogout()
                    return
                }

                dispatch(setUserInfo({
                    code: result.data.account,
                    name: result.data.name,
                    id: result.data.id,
                    roles: result.data.roles,
                    email: result.data.email,
                    menus: result.data.menus || []
                }))
                if (result.data.menus) {
                    //提取用户有权限看到的菜单项
                    const filterMenus = menuitems.filter(d => d.allowAnonymous || result.data?.menus?.some(m => m.key === d.menuitem.key))
                    setFilterMenus(filterMenus)
                    const allowMenus = filterMenus.filter(d => !d.parentKey).map(d => d.menuitem)
                    //后台管理菜单
                    const menu_back: MenuItemType = { key: 'back', label: '后台管理', title: '后台管理', path: '', children: [] }
                    menu_back.children = filterMenus.filter(d => d.parentKey === menu_back.key).map(d => d.menuitem)
                    if (menu_back.children?.length) {
                        allowMenus.push(menu_back)
                    }
                    setAllowMenus(allowMenus)
                    if (refreshonly) {
                        //自动刷新时，检查用户正在浏览的页面是否还有权限
                        if (!result.data.menus.some(d => d.key === selectedItem?.menuitem.key)) {
                            //无权限时跳转到有权限的第一个菜单
                            setSelectedItem(filterMenus[0])
                        }
                        return
                    }
                    if (filterMenus.length) {
                        //从登录页过来时，导航到第一项
                        if (layoutState.fromlogin) {
                            setSelectedItem(filterMenus[0])
                        } else {
                            const cur = filterMenus.find(d => d.menuitem.path === location.pathname)
                            if (cur) {
                                setSelectedItem(cur)
                            } else {
                                setSelectedItem(filterMenus[0])
                            }
                        }
                    }
                }
            } else {
                handleLogout()
            }
        }
    }
    //登出
    const handleLogout = () => {
        delete axios.defaults.headers.common['Authorization']
        localStorage.removeItem('token')
        dispatch(setUserInfo({
            name: '',
            code: '',
            id: 0,
            roles: [],
            email: '',
            menus: []
        }))
        dispatch(setLayoutState({
            ...layoutState,
            header: null,
            scrollTop: 0,
            scrolltobottom: false
        }))
        navigate('/login')
    }
    //点击菜单项
    const handleMenuItemClick = useCallback(({ key }: { key: string }) => {
        if (key === 'back') {
            return
        }
        let item = menuitems.find(d => d.menuitem.key === key)
        console.log(location.pathname)
        if (!item || item.menuitem.path === location.pathname) {
            return
        }

        dispatch(handleRouteChange())
        setSelectedItem(item)

    }, [location.pathname])
    //主区域滚动监听
    const handleContentScroll = (e: UIEvent) => {
        const dom = e.target as HTMLElement
        setContainer(dom)
        const clientHeight = dom.clientHeight
        const scrollTop = dom.scrollTop
        const scrollHeight = dom.scrollHeight
        const scrolltobottom = Math.abs(clientHeight + scrollTop - scrollHeight) < 10
        dispatch(setLayoutState({
            ...layoutState,
            scrolltobottom,
            scrollTop,
            scrollContainer: dom
        }))
    }
    /**监听用户锁定状态 */
    const listenUserLockState = () => {
        clearInterval(checkUserLockState.current.timer)
        checkUserLockState.current.timer = setInterval(() => {
            loadUserInfo(true)
        }, 30 * 1000)
    }

    const phandleRouteChange = (routeitem?: RouteItem | null) => {
        if (!routeitem) {
            return
        }
        if (routeitem.ignoreHTMLTitle) {
            document.title = 'ClinStore'
        } else if (routeitem.noHTMLTitleSuffix) {
            document.title = routeitem.menuitem.title || 'ClinStore'
        } else {
            document.title = `${routeitem.menuitem.title} - ClinStore`
        }
        navigate(routeitem.menuitem.path)
        if (layoutState.scrollContainer) {
            layoutState.scrollContainer.scrollTo(0, 0)
        }
    }
    async function loadPlatformArch() {
        const result = await api_getArch()
        if (result.success && result.data) {
            const arch = result.data?.replace('\n', '') || 'x86_64'
            setPlatform(map_archs[arch] || 'amd64')
        } else {
            setPlatform('amd64')
        }
    }
    function handleSwitchArch() {
        if (selectedItem?.menuitem.key !== 'productlist') {
            message.warn('仅在应用商店可切换')
            return
        }
        if (platform === 'amd64') {
            setPlatform('arm64')
        } else {
            setPlatform('amd64')
        }
    }
    useEffect(() => {
        const cur = filterMenus.find(d => d.menuitem.path === location.pathname) || filterMenus[0]
        setSelectedItem(cur)
        phandleRouteChange(cur)
    }, [location.pathname])
    useEffect(() => {
        phandleRouteChange(selectedItem)
        listenUserLockState()
        return () => {
            clearInterval(checkUserLockState.current.timer)
        }
    }, [selectedItem])
    useEffect(() => {
        loadUserInfo()
        loadPlatformArch()
    }, [])
    useEffect(() => {
        dispatch(setPlatformArch(platform))
    }, [platform])
    const profileMenus = useMemo(() => {
        const menuitems: ItemType[] = [
            {
                key: '2',
                label: (
                    <Button type="link" target="_blank" href={window.StaticConfig.ManualLink}>使用帮助</Button>
                )
            },
            {
                key: '10',
                label: (
                    <Button type="link" danger onClick={handleLogout}>退出</Button>
                )
            }
        ]
        const host = window.location.hostname
        if (devHosts.includes(host)) {
            menuitems.unshift({
                key: '1',
                label: (
                    <Button type="link" onClick={() => setModalChangePwd({ show: true })}>修改密码</Button>
                )
            })
        }
        return menuitems
    }, [selectedItem])
    return {
        selectedItem, setSelectedItem,
        allowMenus,
        userInfo,
        layoutState,
        container, setContainer,
        backTopTarget,
        modalChangePwd, setModalChangePwd,
        platform, setPlatform,
        profileMenus,
        handleLogout,
        handleMenuItemClick,
        handleContentScroll,
        handleSwitchArch
    }
}