import AntDSVGICON from "./antd-icon"
/**用户图标 */
const SVGICON_User = () => (
    <AntDSVGICON>
        <svg width="1em" height="1em" viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false">
            <path d="M20.2805 17.7359C19.7752 16.5391 19.0419 15.4519 18.1216 14.535C17.204 13.6155 16.117 12.8824 14.9207 12.3761C14.9099 12.3708 14.8992 12.3681 14.8885 12.3627C16.5573 11.1574 17.6421 9.19397 17.6421 6.9788C17.6421 3.30915 14.6689 0.335938 10.9992 0.335938C7.32959 0.335938 4.35637 3.30915 4.35637 6.9788C4.35637 9.19397 5.44119 11.1574 7.10994 12.3654C7.09923 12.3708 7.08852 12.3734 7.0778 12.3788C5.8778 12.885 4.80102 13.6109 3.87691 14.5377C2.95738 15.4553 2.22424 16.5423 1.71798 17.7386C1.22063 18.9098 0.952396 20.1655 0.927801 21.4377C0.927086 21.4663 0.932101 21.4948 0.942549 21.5214C0.952997 21.548 0.968668 21.5723 0.988638 21.5927C1.00861 21.6132 1.03247 21.6295 1.05883 21.6406C1.08518 21.6517 1.11349 21.6574 1.14209 21.6574H2.74923C2.86709 21.6574 2.96084 21.5636 2.96352 21.4484C3.01709 19.3806 3.84744 17.444 5.3153 15.9761C6.83405 14.4574 8.85102 13.6217 10.9992 13.6217C13.1474 13.6217 15.1644 14.4574 16.6832 15.9761C18.151 17.444 18.9814 19.3806 19.0349 21.4484C19.0376 21.5663 19.1314 21.6574 19.2492 21.6574H20.8564C20.885 21.6574 20.9133 21.6517 20.9396 21.6406C20.966 21.6295 20.9899 21.6132 21.0098 21.5927C21.0298 21.5723 21.0455 21.548 21.0559 21.5214C21.0664 21.4948 21.0714 21.4663 21.0707 21.4377C21.0439 20.1574 20.7787 18.9118 20.2805 17.7359ZM10.9992 11.5859C9.76976 11.5859 8.61262 11.1065 7.74209 10.2359C6.87155 9.3654 6.39209 8.20826 6.39209 6.9788C6.39209 5.74933 6.87155 4.59219 7.74209 3.72165C8.61262 2.85112 9.76976 2.37165 10.9992 2.37165C12.2287 2.37165 13.3858 2.85112 14.2564 3.72165C15.1269 4.59219 15.6064 5.74933 15.6064 6.9788C15.6064 8.20826 15.1269 9.3654 14.2564 10.2359C13.3858 11.1065 12.2287 11.5859 10.9992 11.5859Z" fill="currentColor"  />
        </svg>
    </AntDSVGICON>
)

export default SVGICON_User