import { configureStore } from "@reduxjs/toolkit"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import layoutSlice from "./layoutSlice"
import userSlice from "./userSlice"

const store = configureStore({
    reducer: {
        user: userSlice.reducer,
        layout: layoutSlice.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        })
})
export default store

// 从 store 本身推断出 `RootState` 和 `AppDispatch` 类型
type RootState = ReturnType<typeof store.getState>
// 推断出类型
type AppDispatch = typeof store.dispatch

// 在整个应用程序中使用，而不是简单的 `useDispatch` 和 `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector