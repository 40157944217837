import { Skeleton } from "cldesign"
import { Suspense, lazy, ReactNode } from "react"
import { HashRouter as Router, Route, Routes } from 'react-router-dom'
import MainLayout from "./pages/layout"


const PageProductList = lazy(() => import('./pages/productlist'))
const PageProductPublish = lazy(() => import('./pages/productpublish'))
const PageInstallPlat = lazy(() => import('./pages/installplat'))
const PageAuthorizeMgr = lazy(() => import('./pages/authorize/manage'))
const PageAuthorizeSummary = lazy(() => import('./pages/authorize/summary'))
const PageAskCode = lazy(() => import('./pages/askcode'))
const PagePrePublish = lazy(() => import('./pages/prepublish'))
const PageProductManage = lazy(() => import('./pages/productmanage'))
const PageCustomerMgr = lazy(() => import('./pages/customermgr'))
const PageRoleManage = lazy(() => import('./pages/rolemanage'))
const PageUserManage = lazy(() => import('./pages/usermanage'))
const PageLogin = lazy(() => import('./pages/usermanage/login'))
const PageMiddleWarePush = lazy(() => import('./pages/middlewarepush'))
const PageVerify = lazy(() => import('./pages/askcode/verify'))
const PageBindRequest = lazy(() => import('./pages/askcode/bindreq'))
const PageAlert = lazy(() => import('./pages/askcode/alert'))

export declare type MenuItemType = {
    danger?: boolean
    disabled?: boolean
    icon?: ReactNode
    key: string
    label: ReactNode
    title?: string
    path: string
    children?: MenuItemType[]
}
export declare type RouteItem = {
    menuitem: MenuItemType
    allowAnonymous?: boolean
    element: JSX.Element
    /**不显示在网页标题中 */
    ignoreHTMLTitle?: boolean
    /**不在网页标题加后缀 */
    noHTMLTitleSuffix?: boolean
    /**父级 */
    parentKey?: string
}
export const menuitems: RouteItem[] = [
    { menuitem: { key: 'productlist', label: '应用商店', title: '应用商店', path: '/productlist' }, element: (<PageProductList />), ignoreHTMLTitle: true },
    { menuitem: { key: 'prepublish', label: '预发布', title: '预发布', path: '/prepublish' }, element: (<PagePrePublish />) },
    { menuitem: { key: 'productpublish', label: '产品发布', title: '产品发布', path: '/productpublish' }, element: (<PageProductPublish />) },
    { menuitem: { key: 'installplat', label: '安装平台', title: '安装平台', path: '/installplat' }, element: (<PageInstallPlat />), noHTMLTitleSuffix: true },
    { menuitem: { key: 'authorizemgr', label: '产品授权', title: '产品授权', path: '/authorizemgr' }, element: (<PageAuthorizeMgr />) },
    { menuitem: { key: 'authsum', label: '授权统计', title: '授权统计', path: '/authsum', disabled: true }, element: (<PageAuthorizeSummary />), parentKey: 'back' },
    { menuitem: { key: 'productmanage', label: '产品管理', title: '产品管理', path: '/productmanage' }, element: (<PageProductManage />), parentKey: 'back' },
    {
        menuitem: { key: 'midwarepush', label: '中间件管理', title: '中间件管理', path: '/midwarepush' }, element: (<PageMiddleWarePush />), parentKey: 'back'
    },
    { menuitem: { key: 'customermgr', label: '客户管理', title: '客户管理', path: '/customermgr' }, element: (<PageCustomerMgr />), parentKey: 'back' },
    { menuitem: { key: 'rolemanage', label: '角色管理', title: '角色管理', path: '/rolemanage' }, element: (<PageRoleManage />), parentKey: 'back' },
    { menuitem: { key: 'usermanage', label: '用户管理', title: '用户管理', path: '/usermanage' }, element: (<PageUserManage />), parentKey: 'back' }
]


const RouterConfig = () => {
    return (
        <Suspense fallback={<Skeleton />}>
            <Router>
                <Routes>
                    <Route path="/" element={<MainLayout />}>
                        {
                            menuitems.filter(d => !d.menuitem.disabled).map(d => (
                                <Route key={d.menuitem.key} path={d.menuitem.path} element={d.element} />
                            ))
                        }
                    </Route>
                    <Route path='/askcode' element={<PageAskCode />} />
                    <Route path='/login' element={<PageLogin />} />
                    <Route path='/verify' element={<PageVerify />} />
                    <Route path='/bindreq' element={<PageBindRequest />} />
                    <Route path='/alert' element={<PageAlert />} />
                </Routes>
            </Router>
        </Suspense>
    )
}

export default RouterConfig;

