import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReactNode } from 'react'

export declare type LayoutState = {
    //临时变量值
    headerTempVals: (string | number)[]
    //头部左侧显示的元素
    header?: ReactNode
    //头部左侧是否显示
    headerVisible?: boolean
    working?: boolean
    fromlogin?: boolean
    /**左侧菜单折叠状态 */
    leftsideCollapsed?: boolean
    //右侧是否已滚动到底
    scrolltobottom?: boolean
    //滚动条距离顶部位置
    scrollTop: number
    //滚动容器
    scrollContainer?: HTMLElement
    //平台架构
    platform?: string
    [key: string]: any
}
const initialState: LayoutState = {
    headerTempVals: [],
    working: false,
    headerVisible: false,
    fromlogin: false,
    scrollTop: 0
}
const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setLayoutState: (state, action: PayloadAction<LayoutState>) => {
            for (let k of Object.keys(action.payload)) {
                state[k] = action.payload[k]
            }
        },
        setLeftsideCollapsed: (state, action: PayloadAction<boolean>) => {
            state.leftsideCollapsed = action.payload
        },
        handleRouteChange: (state) => {
            state.scrollTop = 0
            state.scrolltobottom = false
            state.headerTempVals = []
            state.header = undefined
        },
        setHeader: (state, action: PayloadAction<ReactNode>) => {
            state.header = action.payload
        },
        setHeaderTempVals: (state, action: PayloadAction<(string | number)[]>) => {
            state.headerTempVals = action.payload
        },
        setPlatformArch: (state, action: PayloadAction<string>) => {
            state.platform = action.payload
        }
    }
})

export const { setLayoutState, setLeftsideCollapsed, handleRouteChange, setHeader, setHeaderTempVals, setPlatformArch } = layoutSlice.actions

export default layoutSlice